import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderLogo from "../images/pipp-header-logo.svg";
import { Link } from "gatsby";

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

// this returns a function which takes in the file path as an argument
const images = require.context("../images/prospect-park", false, /\.jpg$/);

const getImagesArray = (images) => {
  // images function has a .keys() function which returns a webpack related filepath
  const imagePaths = images.keys();
  // need to call the webpack function to get compiled filepath - see above
  return imagePaths.map((path) => {
    return <img className="inline-image" src={images(path)} alt="" />;
  });
};

export default () => {
  return (
    <Layout>
      <SEO title="Staten Island" />
      <main className="event-main">
        <header className="nav-header">
          <nav>
            <Link className="logo-link" to="/">
              <img src={HeaderLogo} alt="Home" />
            </Link>
            <div className="heading-container single-event">
              <ul className="header-ul">
                <ListLink to="/about">about</ListLink>
                <ListLink to="/manifesto">manifesto</ListLink>
                <ListLink to="/upcoming">upcoming</ListLink>
                <ListLink to="/past">past</ListLink>
                <ListLink to="/resources">resources</ListLink>
                <ListLink to="/contact">contact</ListLink>
              </ul>
              <h1>
                <i>Patternmaking a Preexisting Garment - Dress</i>
                <br />
                Prospect Park, Brooklyn
                <br />
                07.07.2019
              </h1>
            </div>
          </nav>
        </header>
        <section className="three-grid event-page">
          {getImagesArray(images)}
        </section>
      </main>
    </Layout>
  );
};
